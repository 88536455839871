import React, { Component } from 'react';
import { Navbar,Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import config from 'react-global-configuration';
import {
    HomeOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Redirect } from 'react-router-dom'
import "./config.js";

export default class header extends Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem('loggedin') === 'true';
  }
 
  onLogout = () => {
    sessionStorage.setItem('loggedin', false);
    sessionStorage.setItem('username', "");
    localStorage.setItem('fullUserDetails', "");
    sessionStorage.setItem('deliverycost','');
    window.location.reload();
  };

  componentDidMount() {
    axios.get(config.get('backendURL') + 'company/' + sessionStorage.getItem('company'))
    .then(response => {

      config.set({ 
        OrganisationID : response.data.OrganisationID,
        Name:response.data.Name,
        backendURL:config.get('backendURL'),
        ShopNowItems:response.data.ShopNowItems,
      API_URL: config.get('API_URL'), 
      API_productregistry: config.get('API_productregistry'), 
      rest_API_productregistry: config.get('rest_API_productregistry'), 
      rest_API_productregistry_V2: config.get('rest_API_productregistry_V2'), 
      ProductjwtAud: config.get('ProductjwtAud'), 
      ProductjwtIss: config.get('ProductjwtIss'), 
      ProductprivateKey: config.get('ProductprivateKey'), 
      },{ freeze: false }); 

    })
    .catch(function (error) {
      console.log(error);
    })
  }

  render() {
    if(!this.loggedIn) {
      return <Redirect to='/'/>;
  }
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/welcome">RetailPacx</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
          <Nav.Link href="/welcome">Sites Dashboard</Nav.Link>
            <NavDropdown title="About" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/home">Home</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">FAQ's</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Contact</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Support</NavDropdown.Item>
            </NavDropdown>
            
            <NavDropdown title="Help" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#portal">Support Portal</NavDropdown.Item>
              <NavDropdown.Item href="#contact">Contact Support</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#support">Support</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
             
            <Nav.Link eventKey={2} href="/home">
            <HomeOutlined />
            </Nav.Link>
            <Nav.Link eventKey={2} href="/">
            <LogoutOutlined onClick={this.onLogout.bind()} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
       );
    }
  }