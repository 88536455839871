import React, { Component } from 'react';
import { Form, Layout, Menu, Select, Drawer, Space, Input, Modal, Skeleton, Popover, List, Avatar } from 'antd';
import { Button, Row, Col } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import LeftMenu from "./leftmenu";
import LayoutInclude from "./layout-include";
import config from 'react-global-configuration';
import { WidthProvider, Responsive } from "react-grid-layout";
import * as _ from 'lodash';
import imggraph from './images/chart.png';
import imgreport from './images/download.svg';
import jwt from 'jsonwebtoken';

import {
  PlusCircleOutlined,
  PlusCircleTwoTone,
  AppstoreAddOutlined,
  SlidersOutlined,
  FileImageOutlined,
  VideoCameraAddOutlined,

} from '@ant-design/icons';
import { jssPreset } from '@material-ui/core';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const ResponsiveReactGridLayout = WidthProvider(Responsive);
require('dotenv').config()
const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;

export default class Application extends Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    rowHeight: 20
  };

  constructor(props) {
    super(props);

    const datax = [], datay = [];
    const loading = true;
    this.state = {
      datax, loading, datay, menus: [], layout: [], widgets: [],
      items: [],
      newCounter: 0
    };

    this.onAddItem = this.onAddItem.bind(this);
 
 
  };

  createElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.i;
    return (
      <div key={i} data-grid={el}>

        <span className="text">Move here  </span>

        <LayoutInclude params={{ layoutid: JSON.parse(i)[0], w: el.x, h: el.y }} />

        <span
          className="remove"
          style={removeStyle}
          onClick={this.onLRemoveItem.bind(this, i)}
        >
          x
        </span>


      </div>
    );
  }

  onAddItem(items, itemdetails, itemheading) {
    /*eslint no-console: 0*/
   // console.log("adding", "n" + this.state.newCounter);
 
    const item = 'ITEM';
    const width = 12;
    const height = 4;
    const itemdata = itemdetails;
    const type = items;
    const x = (this.state.items.length * 2) % (this.state.cols || 12);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: "n" + this.state.newCounter,
        i: JSON.stringify([this.state.newCounter.toString(), itemheading, item, itemdata, type]),
        x: x,
        y: Infinity, // puts it at the bottom
        w: width,
        h: height,
      }),
      // Increment the counter to ensure key is always unique.

      newCounter: this.state.newCounter + 1
    });

    var WidgetsDtl = {
      itemheading: itemheading,
      item: item,
      width: width,
      itemdata: itemdata,
      type: type,
      companyid: sessionStorage.getItem('company'),
      height: height,
      x: x,
      y: 1000,
    }
    // console.log(items)

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": process.env.REACT_APP_jwtAud,
      "exp": timestamp,
      "iss": process.env.REACT_APP_jwtIss
    };
 
    var token = jwt.sign(payload, process.env.REACT_APP_secret);
 
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `+token,
      }  };


     axios.post(config.get('backendURL') + 'homelayout/add', WidgetsDtl,requestOptions)
      .then(res => {
        Modal.success({
          title: 'Success',
          content: 'New items has been added',
          onOk() {
            window.location.reload(false);
          },
        });
      });
  }




  onLRemoveItem(i) {
    // console.log("removing", i);
    this.setState({ items: _.reject(this.state.items, { i: i }) });

    //console.log(i)
    axios.delete(config.get('backendURL') + 'homelayout/' + JSON.parse(i)[0])
      .then((res) => {
        Modal.success({
          title: 'Success',
          content: 'Item has been deleted',
          onOk() {
            window.location.reload(false);
          },
        });
      }).catch((error) => {
        console.log(error)
      })
  }



  onwidgetClose = () => {
    this.setState({
      widgetvisible: false,
    });
  };

  componentDidMount() {

    this.setState(() => {
      axios.get(config.get('backendURL') + 'homelayout/' + sessionStorage.getItem('company'))
        .then(response => {
            console.log(response.data)
          if (response.data.length > 0) {
            this.setState({
              items: response.data.map(function (i, key, list) {
                return {
                  i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                  x: i.x,
                  y: i.y,
                  w: i.width,
                  h: i.height,
                  link:i.link,
                  add: i === (list.length - 1)
                };
              }), loading: true
            })
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    });


    const requestMenu = axios.get(config.get('backendURL') + 'widgets')
      .then(response => {
        this.setState({ widgets: response.data })
      })
      .catch((error) => {
        console.log(error);
      })


  }

 

  onLayoutChange = layout => {

   console.log(layout)


    let currentHideNav = (window.innerWidth <= 1024);

  //  console.log(currentHideNav)
    if (!currentHideNav) {
      this.setState({ layout: layout });
      layout.map(function (j, key, list) {
        const pageData = {
          width: j.w,
          height: j.h,
          x: j.x,
          y: j.y,
          itemheading: JSON.parse(j.i)[1],
          item: JSON.parse(j.i)[2],
          type: JSON.parse(j.i)[4],
          itemdata: JSON.parse(j.i)[3],
          companyid: sessionStorage.getItem('company')
        }
        //console.log(JSON.parse(j.i))

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": process.env.REACT_APP_jwtAud,
      "exp": timestamp,
      "iss": process.env.REACT_APP_jwtIss
    };
 
    var token = jwt.sign(payload, process.env.REACT_APP_secret);
 
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `+token,
      }  };

        axios.post(config.get('backendURL') + 'homelayout/update/' + JSON.parse(j.i)[0], pageData, requestOptions)
          .then(res => {
            // console.log(res.data)
           
          });

      });
    }
 
  };

  render() {

    const { widgets } = this.state;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {

      },
      nodeSelector: 'div',
      handleSelector: 'a'
    };
    const { loading } = this.state;
    return (

      <>
        <LeftMenu />

        <Layout className="site-layout">
          <Row>
            <Col sm={12}>

              <Popover placement="bottom" trigger="focus" content={<List itemLayout="horizontal">

                {this.state.widgets.map(widget =>


                  <List.Item>
                    <List.Item.Meta
                      onClick={this.onAddItem.bind(this,widget.item, widget.itemdetails, widget.item)}

                      title={<a style={{ textTransform: 'capitalize' }}>{widget.item}</a>}

                      description={'Create a ' + widget.item + ' widget'}
                    />

                  </List.Item>

                )}



              </List>} >


                <Button
                  variant="outlined"
                  color="primary"

                  className=""
                  startIcon={<PlusCircleOutlined />}
                >
                  Add widget
      </Button>
              </Popover>

              <Popover placement="bottom" trigger="focus" content={<List itemLayout="horizontal">

 
  <List.Item>
    <List.Item.Meta
      onClick={this.onAddItem.bind(this,'bestselling','', 'Best Selling Products')}

      title={<a style={{ textTransform: 'capitalize' }}>Best Selling</a>}

      description={'Add a best selling widget'}
    />

  </List.Item>
  <List.Item>
  <List.Item.Meta
      onClick={this.onAddItem.bind(this,'featured','', 'Featured Products')}

      title={<a style={{ textTransform: 'capitalize' }}>Featured</a>}

      description={'Add a featured widget'}
    />

  </List.Item>
  <List.Item>
  <List.Item.Meta
      onClick={this.onAddItem.bind(this,'featuredoffers','', 'Featured offers')}

      title={<a style={{ textTransform: 'capitalize' }}>Featured offers</a>}

      description={'Add a featured offers widget'}
    />

  </List.Item>
  <List.Item>
  <List.Item.Meta
      onClick={this.onAddItem.bind(this,'offers','', 'Offers')}

      title={<a style={{ textTransform: 'capitalize' }}>Offers</a>}

      description={'Add a offers widget'}
    />

  </List.Item>

</List>} >


<Button
  variant="outlined"
  color="primary"

  className=""
  startIcon={<PlusCircleOutlined />}
>
  Add E-Commerce widget
</Button>
</Popover>

            </Col>
          </Row>

          <ResponsiveReactGridLayout
            onLayoutChange={this.onLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            {...this.props}
          >
            {_.map(this.state.items, el => this.createElement(el))}
          </ResponsiveReactGridLayout>

        </Layout>
      </>
    );
  }
}
